import api from "./api";
import authHeader from "./auth-header";

const API_URL = `user`;

class UserService {
  async getListData(params) {
    try {
      const { data } = await api.get(`${API_URL}`, {
        headers: authHeader(),
        params,
      });
      return data;
    } catch (error) {
      console.log("FAILED REQUST DATA :", error);
    }
  }
  async getData(id) {
    try {
      const { data } = await api.get(`${API_URL}/${id}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      console.log("FAILED REQUST DATA :", error);
    }
  }

  async createData(params) {
    try {
      const { data } = await api.post(`${API_URL}`, params, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      console.log("FAILED POST DATA :", error);
    }
  }

  async updateData(id, params) {
    try {
      const { data } = await api.put(`${API_URL}/${id}`, params, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      console.log("FAILED PUT DATA :", error);
    }
  }

  async deleteData(id) {
    try {
      const { data } = await api.delete(`${API_URL}/${id}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      console.log("FAILED DELETE DATA :", error);
    }
  }
}

export default new UserService();
