<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <BaseTable
            ref="table"
            :columns="columns"
            :service="'user'"
            @actionButton="handleAction"
            @selectedRows="handleSelected"
          >
            <template #header-right>
              <div>
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="$router.push({ name: 'satker-mapping' })"
                >
                  Mapping User To Satker
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="$router.push({ name: 'user-create' })"
                >
                  Create
                </button>
              </div>
            </template>
            <template #array-column="{ data }">
              <div>
                Role:
                <b>{{ data.value.roles.map((r) => r.name).toString() }}</b>
              </div>
              <div>
                Satker:
                <b>{{ data.value.satkers.map((r) => r.name).toString() }}</b>
              </div>
              <div>
                Jabatan:
                <b>
                  {{ data.value.positions.map((r) => r.name).toString() }}
                </b>
              </div>
            </template>
          </BaseTable>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import service from "../../../services/user.service";
import BaseTable from "/src/components/BaseTable.vue";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        { field: "id", title: "ID", isUnique: true, type: "number" },
        { field: "username", title: "Username" },
        { field: "email", title: "Email" },
        { field: "array", title: "Keterangan" },

        // { field: "roles", title: "Role" },
        // { field: "positions", title: "Pangkat" },
        // { field: "satkers", title: "Satker" },
        { field: "actions", title: "Actions" },
      ],
    };
  },
  mounted() {
    // this.getListData();
  },
  methods: {
    handleAction(value) {
      switch (value.type) {
        case "create":
          this.$router.push({ name: "user-create" });
          break;
        case "view":
          this.$router.push({
            name: "user-update",
            params: { id: value.data.id },
          });
          break;
        case "delete":
          this.deleteConfim(value.data.id);
          break;

        default:
          break;
      }
      console.log("action", value);
    },
    deleteConfim(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteAction(id);
          }
        });
    },
    async deleteAction(id) {
      try {
        await service.deleteData(id);
        this.$swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        this.$refs.table.reload();
      } catch (error) {
        console.log(error);
      }
    },

    handleSelected(value) {
      console.log("selected", value);
    },
  },
};
</script>
